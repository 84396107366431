import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import styles from "./Home1.module.css";
import s from "./Home2.module.css";
import Overlay1 from "./components/Overlay1";
import Content1 from "./components/Content1";
import Search from "./components/Search";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { OnlineResourse } from "../Subjects/Components/OnlineResourse";
import MyPagination from "../../components/pagination";
import { baseUrl } from "../UrlList";
import GoogleTranslateButton from "../../Translate/GoogleTranslateButton/GoogleTranslateButton";
import languageContent from "./MultiLanguage/data.json";

import aboveNotice from "../../Images/Photos for Dashboard/Above Notice board/AboveNotice.jpg";
import LearnerStudentYouth from "../../Images/Photos for Dashboard/For Learner, Student and Youth/LearnerStudentYouth.jpg";
import SchoolManagement from "../../Images/Photos for Dashboard/For School Management/SchoolManagement.jpg";
import TeachersEducators from "../../Images/Photos for Dashboard/For Teachers and Educators/TeachersEducators.jpg";
import img_angloAmerica from "../../Images/others1/anglo-americanpng@2x.png";
import img_aws from "../../Images/others1/awspng@2x.png";
import img_cisco from "../../Images/others1/ciscopng@2x.png";
import img_LinkedIn from "../../Images/Company Logos/LinkedIn.png";
import img_microsoft from "../../Images/others1/microsoftpng@2x.png";
import img_path334 from "../../Images/others1/path-334.svg";

const Home1 = () => {
  const [loading, setLoading] = useState(false);
  const [studentOverlay, setStudentOverlay] = useState(false);
  const [profile, setProfile] = useState(null);
  const [filterGradeList, setfilterGradeList] = useState(null);
  const [filterSubjectList, setfilterSubjectList] = useState(null);
  // const [subMenuList, setsubMenuList] = useState([]);

  const [currentTypeId, setCurrentTypeId] = useState();
  const [profile_SubMenu_Pair, setprofile_SubMenu_Pair] = useState();
  //search
  const [filterCurrentSearchValue, setfilterCurrentSearchValue] =
    useState(null);
  const [filterCurrentGradeId, setfilterCurrentGradeId] = useState(null);
  const [filterCurrentSubjectId, setfilterCurrentSubjectId] = useState(null);
  const [filterCurrentPage, setfilterCurrentPage] = useState(1);
  const [filterCurrentResponse, setfilterCurrentResponse] = useState();

  const [reSetSearchValues, setreSetSearchValues] = useState();
  const [currentLanguage, setcurrentLanguage] = useState("en");

  const [MultiLanguageData, setMultiLanguageData] = useState();

  //fom Navbar lonk state
  // const location = useLocation();
  // let { currenFilterSearchValueFromLogo } = location.state;
  // useEffect(() => {
  //   // setfilterCurrentResponse(currenFilterSearchValueFromLogo);
  //   setfilterCurrentSearchValue(undefined);
  //   setfilterCurrentGradeId(undefined);
  //   setfilterCurrentSubjectId(undefined);
  // }, [currenFilterSearchValueFromLogo]);

  // populate user, grades

  //fetch data from api start
  const profileUrl = baseUrl + "profile/list/";
  const subMenuUrl = baseUrl + "sub/menu/list/";
  const filterGradeUrl = baseUrl + "filter/grade/list";
  const filterSubjectUrl = baseUrl + "filter/subject/list";
  const searchListUrl = baseUrl + "filter/search/list";

  let subMenuList = [];
  // let profile_SubMenu_Pair;
  // let currentTypeId;

//   async function fetchData() {
//     try {
//       const response = await fetch('http://localhost:3000/cors', { mode: 'cors' });
//       console.log(response);

//       const data = await response.json();
//       console.log('tryy check');
//       console.log(data);
//     } catch (error) {
//       console.log('error check');

//       console.log(error + 'error');
//     }
//   }
// fetchData();  

  useEffect(() => {

    // subMenuList = [];
    const title = () => {
      document.title = "Home | Woza Geleza";
    };
    title();
    const fetchData = async () => {
      // ,
      // {
      //   headers: {
      //     'Access-Control-Allow-Origin': 'http://localhost:3000',
      //     'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      //     'Access-Control-Allow-Headers': 'Content-Type',
      //   },
      // }

      // ,
      // {
      //   headers: {
      //     'Access-Control-Allow-Origin': 'https://woza-geleza-prod-136324741.eu-west-1.elb.amazonaws.com/',
      //     'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      //     'Access-Control-Allow-Headers': 'Content-Type',
      //   },
      // }

         // ,
      // {
      //   headers: {
      //     'Access-Control-Allow-Origin': 'https://woza-geleza.com/',
      //     'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      //     'Access-Control-Allow-Headers': 'Content-Type',
      //   },
      // }

      const fetchProfile = await axios.get(profileUrl
     
       
        ).then(function (res) {
        // console.log("res", res);
        // console.log("res?.data.success", res?.data.message);

        res.data.success == false ? setProfile(null) : setProfile(res.data);
        return res.data;
      });

      await fetchProfile?.data?.map(async (value, index) => {
        // console.log("value", value);
        // console.log("subMenuUrl", subMenuUrl);
        await axios.get(subMenuUrl + value.typeID).then(function (res) {
          // console.log("res", res);
          subMenuList.push(res);
        });
        // console.log("profileprofileprofile", fetchProfile.data);
        //q

        // console.log("subMenuListsubMenuList", subMenuList);
        // setprofile_SubMenu_Pair(
        //   fetchProfile.data.map((val, index) => ({
        //     [val.typeID]: subMenuList[index],
        //   }))
        // );
        // console.log("profile_SubMenu_Pair", profile_SubMenu_Pair);
      });
      await axios.get(filterGradeUrl).then(function (res) {
        setfilterGradeList(res.data);
        return res.data;
      });
      await axios.get(filterSubjectUrl).then(function (res) {
        setfilterSubjectList(res.data);
        return res.data;
      });
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [third])

  const navigate = useNavigate();
  function navigateToNotice() {
    navigate("/Notice");
  }
  // grade overlay show hide

  function OnOverlay(id) {
    setCurrentTypeId(id);
    setStudentOverlay(true);
  }

  function OffOverlay() {
    setStudentOverlay(false);
  }
  function leaveOverlay() {
    setStudentOverlay(false);
  }

  function OnClickOverlay(para1, para2) {
    setCurrentTypeId(para1);
    setStudentOverlay(para2);
  }

  // student, Teacher onclick event
  let studentProfileId = profile == null ? null : profile.data[0].typeID;
  let TeacherProfileId = profile == null ? null : profile.data[2].typeID;
  const firstOverlay = document.getElementById("firstOverlay");

  function scrollToOverlay(id) {
    setCurrentTypeId(id);
    window.scrollTo(0, firstOverlay);
    setStudentOverlay(true);
  }

  //search
  function handleCurrentSearchValueAndId(
    filterSearchValue,
    filterCurrentGradeId,
    filterCurrentSubjectId
  ) {
    setfilterCurrentSearchValue(filterSearchValue);
    setfilterCurrentGradeId(filterCurrentGradeId);
    setfilterCurrentSubjectId(filterCurrentSubjectId);
  }
  let searchRequestBody = {
    gradeID: filterCurrentGradeId,
    subjectID: filterCurrentSubjectId,
    search: filterCurrentSearchValue,
    page: filterCurrentPage,
    limit: 30,
  };
  // {
  //   gradeID: filterCurrentGradeId,
  //   subjectID: filterCurrentSubjectId,
  //   search: filterCurrentSearchValue,
  //   page: filterCurrentPage,
  //   limit: 100,
  // };

  useEffect(() => {
    const fetchData = async () => {
      filterCurrentSearchValue == null &&
      filterCurrentGradeId == null &&
      filterCurrentSubjectId == null
        ? setfilterCurrentResponse(undefined)
        : // filterCurrentSearchValue == ""
          // ? setfilterCurrentResponse(undefined)

          // :
          // : filterCurrentGradeId == undefined
          // ? setfilterCurrentResponse(undefined)
          // : filterCurrentSubjectId == undefined
          // ? setfilterCurrentResponse(undefined)
          // console.log(
          //   " before api call filterCurrentResponse ",
          //   filterCurrentResponse
          // );

          await axios.post(searchListUrl, searchRequestBody).then((res) => {
            setfilterCurrentResponse(res);
            // console.log("filterCurrentResponse 1111111", filterCurrentResponse);
          });
    };
    fetchData();
  }, [
    filterCurrentSearchValue,
    filterCurrentGradeId,
    filterCurrentSubjectId,
    filterCurrentPage,
  ]);

  function resetSearchStates() {
    // setfilterCurrentSearchValue(null);
    // setfilterCurrentGradeId(null);
    // setfilterCurrentSubjectId(null);
    // setfilterCurrentPage(1);
    window.location.reload();
  }

  //pagination
  function functionSetcurrentPage(param) {
    setfilterCurrentPage(param);
  }

  function handleLanguage4(parameter) {
    // console.log("handleLanguage4 parameter", parameter);
    setcurrentLanguage(parameter);
    // setMultiLanguageData(multiLanguage);
    // console.log("multiLanguage", multiLanguage);
  }

  return (
    <>
      {/* {console.log("multiLanguage", MultiLanguageData)} */}
      {/* {console.log("currentLanguage", currentLanguage)} */}
      {/* {console.log(
        "filterCurrentResponse",
        filterCurrentResponse == undefined
          ? filterCurrentResponse
          : filterCurrentResponse.data.data.result
      )} */}
      {/* {console.log(
        "profile",
        profile,
        "subMenuList",
        subMenuList,
        "setprofile_SubMenu_Pair",
        profile_SubMenu_Pair
      )} */}
      {/* {console.log("searchRequestBody", searchRequestBody)} */}
      {/* {console.log("filterCurrentSearchValue", filterCurrentSearchValue)} */}
      {/* {console.log("filterCurrentResponse", filterCurrentResponse)} */}
      <div className={styles.home1}>
        {/* {option} */}

        <span className={styles.home1} onClick={OffOverlay}>
          <Navbar
            resetSearchStates={resetSearchStates}
            handleLanguage3={handleLanguage4}
          />
        </span>

        <div className={styles.selector}>
          <div className={styles.userselector}>
            {loading
              ? null
              : profile?.success == false
              ? // <p>{profile?.message}</p>
                null
              : profile?.data?.map((item) => (
                  <div className={styles.selectorbox} key={item.typeID}>
                    <p
                      className={styles.learner}
                      onPointerEnter={
                        // item.name == "Teacher" ? OnOverlayTeacher : OnOverlay
                        () => OnOverlay(item.typeID)
                      }
                      onClick={() =>
                        OnClickOverlay(item.typeID, () =>
                          setStudentOverlay(true)
                        )
                      }
                    >
                      {currentLanguage == "en"
                        ? item.name
                        : currentLanguage == "zu"
                        ? languageContent?.[currentLanguage]?.[
                            item.name.toLowerCase()
                          ]
                          ? languageContent?.[currentLanguage]?.[
                              item.name.toLowerCase()
                            ]
                          : item.name
                        : item.name}
                      {/* {console.log(
                        "qqqq",
                        Object.keys(languageContent?.en).map((val) => val),
                        typeof languageContent
                      )} */}
                    </p>
                    <div className={styles.divider} />
                  </div>
                ))}
          </div>

          <div className={styles.searchContent} onClick={OffOverlay}>
            <Search
              filterGradeListProps={filterGradeList}
              filterSubjectListProps={filterSubjectList}
              handleCurrentSearchValueAndId={handleCurrentSearchValueAndId}
            />
          </div>
        </div>

        {/* <GoogleTranslateButton /> */}

        <div
          className={styles.firstOverlay}
          id="firstOverlay"
          style={{ display: studentOverlay ? "block" : "none" }}
          onMouseLeave={() => leaveOverlay()}
        >
          <Overlay1
            currentTypeId={currentTypeId}
            // pair={profile_SubMenu_Pair}
            // subMenuList={subMenuList}
          />
        </div>
        {filterCurrentResponse == undefined ? (
          <div>
            <div onClick={OffOverlay}>
              <Content1
                languageContent={languageContent}
                currentLang={currentLanguage}
              />
            </div>

            <div className={styles.sponsorcontainer} onClick={OffOverlay}>
              <h3 className={styles.sponsors}>Sponsors</h3>
              <div className={styles.sponsorlist}>
                <img
                  className={styles.angloAmericanIcon}
                  alt=""
                  src={img_angloAmerica}
                  loading="lazy"
                />
                <img
                  className={styles.awsIcon}
                  alt=""
                  src={img_aws}
                  loading="lazy"
                />
                <img
                  className={styles.ciscoIcon}
                  alt=""
                  src={img_cisco}
                  loading="lazy"
                />
                <img
                  className={styles.microsoftIcon}
                  alt=""
                  src={img_microsoft}
                  loading="lazy"
                />
                <div className={styles.sponsorlist1}>
                  <img
                    className={styles.linkedinIcon}
                    alt=""
                    src={img_LinkedIn}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className={styles.content2} onClick={OffOverlay}>
              <div className={styles.content2top}>
                <h3 className={styles.content2title}>
                  {languageContent?.[currentLanguage]?.AboutOurContent}
                </h3>
                <p className={styles.content2para1}>
                  {languageContent?.[currentLanguage]?.AboutOurContent1}
                </p>
                <p className={styles.content2para2}>
                  {languageContent?.[currentLanguage]?.AboutOurContent2}
                </p>
                <p className={styles.content2para2}>
                  {languageContent?.[currentLanguage]?.AboutOurContent3}
                </p>
              </div>
              <div className={styles.content2bottom}>
                <h3 className={styles.content2bottompara1}>
                  {languageContent?.[currentLanguage]?.WorkingTogether}
                </h3>
                <p className={styles.content2bottompara2}>
                  {languageContent?.[currentLanguage]?.WorkingTogetherContent1}
                </p>
                <a
                  // href="https://southafrica.angloamerican.com/our-difference/anglo-american-south-africa-education-programme"
                  href="https://southafrica.angloamerican.com/our-difference/education-programme"

                  target="_blank"
                >
                  <button className={styles.btn2}>
                    {/* <span className={styles.content2bottombuttontext}> */}
                    Learn More
                    {/* </span> */}
                  </button>
                </a>
              </div>
            </div>

            <div className={styles.content3}>
              <div className={styles.content31}>
                <div className={styles.content31left}>
                  {/* <div className={styles.content31leftimg1}>
                <img
                  className={styles.vectorIcon}
                  alt=""
                  src=""
                  loading="lazy"
                />
              </div> */}
                  <img
                    className={styles.content31leftimg2Icon}
                    alt=""
                    src={SchoolManagement}
                    loading="lazy"
                  />
                </div>
                <div className={styles.content31rignt}>
                  <h3 className={styles.content31rignttitle}>
                    {languageContent?.[currentLanguage]?.ForSchool}
                  </h3>
                  <p className={styles.content31rigntpara}>
                    {languageContent?.[currentLanguage]?.ForSchoolContent1}
                  </p>
                  {/* <button className={styles.content31rigntbutton}>
                <span className={styles.content2bottombuttontext}>
                  Learn More
                </span>
              </button> */}
                </div>
              </div>
              <div className={styles.content32}>
                <div className={styles.content31rignt}>
                  <h3 className={styles.content32lefttitle}>
                    {languageContent?.[currentLanguage]?.ForTeachers}
                  </h3>
                  <p className={styles.content31rigntpara}>
                    {languageContent?.[currentLanguage]?.ForTeachersContent}

                    {/* This section is aimed at teachers and school coaches. A
                    number of resources are provided including lesson plans,
                    past tests, activities and challenges that you can use for
                    your class and Learners. No registration or login required. */}
                  </p>
                  <button
                    onClick={() => scrollToOverlay(TeacherProfileId)}
                    className={styles.btn2}
                  >
                    {/* <span className={styles.content32leftbuttontext}> */}
                    Learn More
                    {/* </span> */}
                  </button>
                </div>
                <div className={styles.content31left}>
                  {/* <div className={styles.content32rightimg1}>
                <img
                  className={styles.vectorIcon1}
                  alt=""
                  src=""
                  loading="lazy"
                />
              </div> */}
                  <img
                    className={styles.content32rightimg2Icon}
                    alt=""
                    src={TeachersEducators}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className={styles.content33}>
                <div className={styles.content31left}>
                  {/* <div className={styles.content33leftimg1}>
                <img
                  className={styles.vectorIcon2}
                  alt=""
                  src=""
                  loading="lazy" 
                />
              </div> */}
                  <img
                    className={styles.content33leftimg2Icon}
                    alt=""
                    src={LearnerStudentYouth}
                    loading="lazy"
                  />
                </div>
                <div className={styles.content31rignt}>
                  <h3 className={styles.content33righttitle}>
                    {/* For Learner and Youth */}
                    {languageContent?.[currentLanguage]?.ForLearner}
                  </h3>
                  <p className={styles.content31rigntpara}>
                    {languageContent?.[currentLanguage]?.ForLearnerContent}

                    {/* This section is aimed at learners and youth. It consists of
                    a number of resources available in the South African space
                    to help you improve your learning and education. Additional
                    resources will be continuously added. The content varies and
                    consists of materials, videos, and self paced training. Some
                    of the ICT courses offer accredited certification. */}
                  </p>
                  {/* <button onClick={scrollToStudentOverlay}> */}
                  <button
                    onClick={() => scrollToOverlay(studentProfileId)}
                    // className={styles.content31rigntbutton}
                    className={styles.btn2}
                  >
                    {/* <span className={styles.content33rightbuttontext}> */}
                    Learn More
                    {/* </span> */}
                  </button>
                  {/* </button> */}
                </div>
              </div>
            </div>
            <div className={styles.content4}>
              <div className={styles.imgAboveNoticeBox}>
                <img
                  className={styles.content41imgIcon}
                  alt="Image"
                  src={aboveNotice}
                  loading="lazy"
                />
              </div>
              <div className={styles.content42}>
                <div className={styles.content42content}>
                  <h3 className={styles.content42contenttitle}>
                    {/* Notice Board */}
                    {languageContent?.[currentLanguage]?.NoticeBoardHeading}
                  </h3>
                  <p className={styles.content42contentpara}>
                    {
                      languageContent?.[currentLanguage]
                        ?.NoticeBoardHeadingContent
                    }

                    {/* Do check out the notice board for announcements on new
                    material, challenges and competitions that are open to
                    learners and educators. If you would like to make
                    announcements on our notice board, please do contact us. */}
                  </p>

                  <button
                    onClick={navigateToNotice}
                    // className={styles.content42contentbutton}
                    className={styles.btn2}
                  >
                    {/* <span className={styles.content42contentbuttontext}> */}
                    {
                      languageContent?.[currentLanguage]
                        ?.NoticeBoardHeadingButton
                    }

                    {/* Go to Notice Board */}
                    {/* </span> */}
                  </button>
                </div>
                <div className={styles.content42background} />
              </div>
              <div className={styles.content43}>
                <div className={styles.content43img}>
                  <img
                    className={styles.path334Icon}
                    alt=""
                    src={img_path334}
                    loading="lazy"
                  />
                </div>
                <p className={styles.content43quote}>
                  “Education is the most powerful weapon which you can use to
                  change the world.”
                </p>
                <p className={styles.content43authorname}>-- Nelson Mandela</p>
              </div>
            </div>

            <Footer />
          </div>
        ) : filterCurrentResponse?.data?.data?.result?.length == 0 ? (
          <h1 className={s.notFound}>{filterCurrentResponse.data.message}</h1>
        ) : (
          // filterCurrentResponse?.data.data.result.map((val) => (
          <div onClick={OffOverlay} className={s.searchResult}>
            {/* <div> */}
            {/* <p>hhhq</p> */}
            <div className={s.pagination}>
              <MyPagination
                viewAllContent={filterCurrentResponse}
                setcurrentPage={functionSetcurrentPage}
              />
            </div>
            <OnlineResourse
              content={filterCurrentResponse?.data?.data?.result}
              forMultipleColumns={true}
            />
            {/* <MyPagination
              viewAllContent={filterCurrentResponse}
              setcurrentPage={functionSetcurrentPage}
            /> */}
          </div>
          // ))
          // <h4>result</h4>
        )}
      </div>
    </>
  );
};

export default Home1;
