import React, { useEffect, useState } from "react";

import GoogleTranslateButton from "./GoogleTranslateButton/GoogleTranslateButton";
import s from "./Translate.module.css";
import arrow from "../Images/icons/expand_less.svg";
import MultiLanguageContent from "../pages/home/MultiLanguage/data.json";

function Translate(props) {
  const [lang, setlang] = useState("en");
  function languageChange() {
    setlang(event.target.checked ? event.target.value : "en");
  }

  useEffect(() => {
    props?.handleLanguage1(lang);
    props?.handleMultiLanguageData == undefined
      ? null
      : props?.handleMultiLanguageData(MultiLanguageContent);
  }, [lang]);

  return (
    <>
      {/* {console.log(lang)} */}
      <div className={s.box}>
        {/* <label htmlFor="zulu" className={s.text}>
          Zulu
        </label>
        <input
          id="zulu"
          type="checkbox"
          value="zu"
          onChange={languageChange}
          className={s.checkbox}
        />
        <div className={s.otherLangBox}>
          <div className={s.innerBox}>
            <img
              src={arrow}
              alt=""
              width="20px"
              height="20px"
              className={s.arrow}
            />
            <p className={s.text}>Other Languages</p>
          </div>
          <div className={s.GTranslate}>
            <GoogleTranslateButton />
          </div>
        </div> */}
        <div className={s.onlyGoogleTranslate}>
          <GoogleTranslateButton />
        </div>
      </div>
    </>
  );
}

export default Translate;
