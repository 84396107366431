import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Drawer from "../components/Drawer";
import PortalDrawer from "../components/PortalDrawer";
import { signInpageLink } from "../pages/UrlList";
import GoogleTranslateButton from "../Translate/GoogleTranslateButton/GoogleTranslateButton";
import Translate from "../Translate/Translate";
import styles from "./Navbar.module.css";
import s from "./Navbar2.module.css";
import img_component from "../Images/others1/component-2.svg";
import img_logo from "../Images/others1/wozagalezalogo2xpng.svg";

const Navbar = (props) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [MultiLanguageData, setMultiLanguageData] = useState();
  const [CurrentLanguage, setCurrentLanguage] = useState();

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const [GtranslateShow, setGtranslateShow] = useState(false);

  function hideTranslate() {
    setGtranslateShow(false);
  }

  function handleMultiLanguageData1(parameter) {
    setMultiLanguageData(parameter);
  }

  function handleLanguage2(parameter) {
    // props?.handleLanguage3 == undefined
    //   ? null
    //   :
    props?.handleLanguage3 ? props?.handleLanguage3(parameter) : null;
    setCurrentLanguage(parameter);
  }

  return (
    <>
      {/* {console.log("props?.handleLanguage3", props?.handleLanguage3)} */}
      {/* {console.log("MultiLanguageData", MultiLanguageData)} */}
      <nav
        className={styles.navbar}
        //  onPointerDown={hideTranslate}
      >
        <div className={styles.navContent}>
          <Link
            to="/"
            state={{ currenFilterSearchValueFromLogo: undefined }}
            className={styles.homelink}
            onClick={props.resetSearchStates}
            onPointerEnter={hideTranslate}
          >
            <img className={styles.navLogoIcon} alt="" src={img_logo} />
          </Link>
          <div className={styles.navContentLinks}>
            <div className={styles.navContentLinks1}>
              <Link
                to="/Notice"
                className={styles.navLinkNoticeBoard}
                onPointerEnter={hideTranslate}
              >
                {/* Notice Board */}
                {MultiLanguageData?.[CurrentLanguage]?.NoticeBoard}
              </Link>
              <Link
                to="/FAQ"
                className={styles.navLinkFaq}
                onPointerEnter={hideTranslate}
              >
                FAQ
              </Link>
              <Link
                to="/Help"
                className={styles.navLinkHelp}
                onPointerEnter={hideTranslate}
              >
                {/* Help */}
                {MultiLanguageData?.[CurrentLanguage]?.Help}
              </Link>

              <div
                className={`  ${styles.language}`}
                // onPointerEnter={() => setGtranslateShow(true)}
              >
                <p
                  className={styles.navLinkHelp}
                  onPointerDown={() => setGtranslateShow(!GtranslateShow)}
                  style={{
                    color: GtranslateShow ? "white" : "var(--color-gray-500)",
                  }}
                >
                  Language
                </p>
                <div
                  className={styles.Gtranslate}
                  style={{ display: GtranslateShow ? "block" : "none" }}
                >
                  {/* <GoogleTranslateButton /> */}

                  {window.innerWidth < 610 ? null : (
                    <Translate
                      handleLanguage1={handleLanguage2}
                      handleMultiLanguageData={handleMultiLanguageData1}
                    />
                  )}
                </div>
              </div>
            </div>

            <a
              target="_blank"
              href={
                // "https://woza.geleza.admin.rudimax.com/"
                signInpageLink
              }
              onPointerEnter={hideTranslate}
            >
              <button className={styles.navLoginButton}>
                <span className={styles.login}>Login</span>
              </button>
            </a>
          </div>
        </div>
        <button className={styles.toggleButton} onClick={openDrawer}>
          <img
            className={styles.toogleBtnImageIcon}
            alt=""
            src={img_component}
          />
        </button>
        {/* {window.innerWidth < 600 ? <p>below 600</p> : <p>above 600</p>} */}
        {/* {window.matchMedia("(max-width:600px)").matches ? (
          <p>below 600</p>
        ) : (
          <p>above 600</p>
        )} */}
        {/* {console.log("window.innerwidth", window.innerWidth)} */}
      </nav>
      {isDrawerOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeDrawer}
        >
          <Drawer onClose={closeDrawer} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Navbar;

export function translateButton() {
  return <h1>hello</h1>;
}
