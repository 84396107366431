import { Link } from "react-router-dom";
import styles from "./content1.module.css";
import Learn1 from "../../../Images/Photos for Dashboard/Let's learn together/Learn1.jpg";
import Learn2 from "../../../Images/Photos for Dashboard/Let's learn together/Learn2.jpg";
import Learn3 from "../../../Images/Photos for Dashboard/Let's learn together/Learn3.jpg";
import img_homebutton from "../../../Images/others1/homebuttonpng@2x.png";

const Content1 = (props) => {
  return (
    <>
      {/* {console.log("props.currentLang", props.currentLang)} */}
      <div className={styles.container}>
        {/* <div className={styles.content1} /> */}
        <div className={styles.box}>
          <div className={styles.left}>
            <h3 className={styles.title}>
              {props.languageContent?.[props.currentLang]?.learntogether}
            </h3>
            <p className={styles.para1}>
              {
                props.languageContent?.[props.currentLang]
                  ?.learnTogetherContent1
              }
            </p>
            <p className={styles.para2}>
              {
                props.languageContent?.[props.currentLang]
                  ?.learnTogetherContent2
              }
            </p>
            <div className={styles.content1buttons}>
              <button className={styles.ictbutton}>
                <Link
                  to="/Ict"
                  state={{
                    languageContent: props?.languageContent,
                    currentlang: props.currentLang,
                  }}
                >
                  <span className={styles.ictbuttoncontent}>
                    {/* ICT Certified Courses */}
                    {
                      props.languageContent?.[props.currentLang]
                        ?.ICTCertifiedCourses
                    }
                  </span>
                </Link>
              </button>

              <button className={styles.dbebutton}>
                <a
                  href="https://dbecloud.org.za/login/welcome.php"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.dbebuttoncontent}>
                    <span className={styles.dbebuttontext}>DBE iCloud</span>
                    <img
                      className={styles.dbebuttonimgIcon}
                      alt=""
                      src={img_homebutton}
                      loading="lazy"
                    />
                  </div>
                </a>
              </button>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.threeInOne}>
              <div className={styles.rightBox}>
                <img
                  className={styles.rightImg}
                  alt=""
                  src={Learn1}
                  loading="lazy"
                />
              </div>
              <div className={styles.rightBox}>
                <img
                  className={styles.rightImg}
                  alt=""
                  src={Learn2}
                  loading="lazy"
                />
              </div>
              <div className={styles.rightBox}>
                <img
                  className={styles.rightImg}
                  alt=""
                  src={Learn3}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content1;
