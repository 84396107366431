import book from "../../../Images/Company Logos/Grades/book.svg";
import beaker from "../../../Images/Company Logos/Grades/beaker.svg";
import target from "../../../Images/Company Logos/Grades/target.svg";
import timer from "../../../Images/Company Logos/Grades/timer.svg";

const gradeBottom = [
  {
    gradeImg: book,
    gradeTitle: 1,
    backgroundColor: "#80B1FF",
  },
  {
    gradeImg: beaker,
    gradeTitle: 2,
    backgroundColor: "#F2629B",
  },
  {
    gradeImg: target,
    gradeTitle: 3,
    backgroundColor: "#83E4DD",
  },
  {
    gradeImg: timer,
    gradeTitle: 1,
    backgroundColor: "#80B1FF",
  },
  {
    gradeImg: book,
    gradeTitle: 1,
    backgroundColor: "#80B1FF",
  },
  {
    gradeImg: beaker,
    gradeTitle: 2,
    backgroundColor: "#F2629B",
  },
  {
    gradeImg: target,
    gradeTitle: 3,
    backgroundColor: "#83E4DD",
  },
  {
    gradeImg: timer,
    gradeTitle: 1,
    backgroundColor: "#80B1FF",
  },
  {
    gradeImg: book,
    gradeTitle: 1,
    backgroundColor: "#80B1FF",
  },
  {
    gradeImg: beaker,
    gradeTitle: 2,
    backgroundColor: "#F2629B",
  },
  {
    gradeImg: target,
    gradeTitle: 3,
    backgroundColor: "#83E4DD",
  },
  {
    gradeImg: timer,
    gradeTitle: 1,
    backgroundColor: "#80B1FF",
  },
];

export default gradeBottom;
