import React, { useState } from "react";
import s from "./Ict.module.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
// import "../../components/Common Css/Common.scss";
// import data from "./data.json";
import aws from "../../Images/Company Logos/aws.png";
import cisco from "../../Images/Company Logos/cisco.png";
import networkAcademy from "../../Images/Company Logos/NetworkingAcademy.png";
import linkedin from "../../Images/Company Logos/LinkedIn.png";

import { Link, useLocation } from "react-router-dom";
import arrow from "../../Images/icons/arrow.svg";
export default function Ict() {
  // const [curentLanguage, setcurentLanguage] = useState("en");
  let { state } = useLocation();
  let { languageContent, currentlang } = state;
  const [language, setlanguage] = useState(currentlang);

  function handleLanguage4(parameter) {
    // props?.handleLanguage3 == undefined
    //   ? null
    //   :
    // props?.handleLanguage3 ? props?.handleLanguage3(parameter) : null;
    // setcurentLanguage(parameter);
    setlanguage(parameter);
  }

  const data = [
    {
      image: aws,
      heading: "AWS Digital Training",
      companyLink: "aws.amazon.com/education/awseducate",
      content:
        "Through AWS Educate, students have access to content developed to skill up for cloud careers in growing fields. AWS Educate also connects companies hiring for cloud skills to qualified student job seekers with the AWS Educate Job Board.",
      redirect: "https://aws.amazon.com/education/awseducate/",
    },
    {
      image: cisco,
      heading: "NetAcad",
      companyLink: "www.netacad.com",
      content:
        "Cisco Networking Academy transforms the lives of learners, educators and communities through the power of technology, education and career opportunities. Available to anyone, anywhere.",
      redirect: "https://www.netacad.com/",
    },
    {
      image: networkAcademy,
      heading: "Skills For All",
      companyLink: "skillsforall.com",
      content:
        "Skills for All is a free, mobile-first program that delivers leading-edge, personalized learning experiences for wherever you are on your journey. Explore the world of IT through our curated career pathways, self-paced courses, interactive tools, and",
      redirect: "https://skillsforall.com/",
    },
    {
      image: linkedin,
      heading: "LinkedIn",
      companyLink: "opportunity.linkedin.com/skills-for-in-d...",
      content:
        "Start developing your skills for free with learning paths from LinkedIn Learning and Microsoft Learn, then practice tech skills in the GitHub Learning Lab.",
      redirect: "https://opportunity.linkedin.com/skills-for-in-demand-jobs",
    },
  ];

  return (
    <>
      {console.log("state", state.languageContent)}
      {console.log("languageContent", languageContent)}
      {console.log(
        "languageContent?.[currentlang]?.ICTTrainingProgrammeHeading",
        languageContent?.[currentlang]?.ICTTrainingProgrammeHeading
      )}
      {console.log("language", language)}
      <Navbar handleLanguage3={handleLanguage4} />
      <div className={s.section}>
        <div className={s.titleTop}>
          <Link to="/">
            <div className={s.backButton}>
              {/* <img
                className={s.backArrow}
                src="./icons/expand_less.png"
                alt="arrow"
              /> */}
              <span className="material-icons">navigate_before</span>
              <span className={s.Back}>Back to Home</span>
            </div>
          </Link>
          <h3 className={s.Title}>
            {/* ICT Training Programme */}
            {state.languageContent?.[language]?.ICTTrainingProgrammeHeading}
          </h3>
        </div>

        <p className={s.titlePara}>
          {state.languageContent?.[language]?.ICTTrainingProgrammeSubHeading}

          {/* The following ICT certified courses have been made available by our
          various partners. These courses offer you the opportunity to build
          basic and advanced ICT skills, and obtain a certification upon
          completion. By clicking on the various courses, it will redirect you
          to the providers website with further information and instruction on
          how to access and do the training. */}
        </p>
        <div className={s.contentBox}>
          {data.map((items) => (
            <div className={s.box}>
              <div className={s.imgBox}>
                <img className={s.img} src={items.image} alt="" />
              </div>
              <h6 className={s.heading}>{items.heading}</h6>
              <p className={s.link}>{items.companyLink}</p>
              <p className={s.paraBox}>{items.content}</p>

              <button className={s.btn}>
                <a
                  href={items.redirect}
                  target="_blank"
                  className={s.buttonnContent}
                >
                  <span>Visit</span>
                  <img
                    className={s.btnArrow}
                    src={arrow}
                    alt=""
                    width="20px"
                    height="20px"
                  />
                </a>
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
