import React, { useEffect } from "react";
import s from "./GoogleTranslateButton.module.css";

const GoogleTranslateButton = () => {
  useEffect(() => {
    const googleTranslateScript = document.createElement("script");
    googleTranslateScript.type = "text/javascript";
    googleTranslateScript.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    googleTranslateScript.async = true;
    // console.log("googleTranslateScript", googleTranslateScript);

    document.body.appendChild(googleTranslateScript);

    // let testElement = document
    //   .getElementById("test")
    //   .appendChild(googleTranslateScript);

    // testElement.innerHTML = "hello";

    // const translateContent = document.createElement("script");
    // translateContent.text = function googleTranslateElementInit() {
    //   new google.translate.TranslateElement(
    //     {
    //       pageLanguage: "en",
    //       autoDisplay: true,
    //       layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
    //     },
    //     "google_translate_element"
    //   );
    // };

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          // includedLanguages: "en,zu,af,xh",
          includedLanguages: "en,zu",

          // autoDisplay: true,
          autoDetect: true,
          // multilanguagePage: true,
          // layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        },
        "google_translate_elements"
      );

      // var $googleDiv = $("#google_translate_elements .skiptranslate");
      // var $googleDivChild = $("#google_translate_elements .skiptranslate div");
      // $googleDivChild.next().remove();

      // $googleDiv
      //   .contents()
      //   .filter(function () {
      //     return this.nodeType === 3 && $.trim(this.nodeValue) !== "";
      //   })
      //   .remove();
    };
  }, []);

  return (
    <>
      <div id={s.box}>
        <div className={s.borderBox}></div>
        <div
          id="google_translate_elements"
          className={s.google_translate_element}
        ></div>
      </div>
    </>
  );
};

export default GoogleTranslateButton;
