import s from "./OnlineResourse.module.css";
import React, { useState } from "react";
import pdfImg from "../../../Images/File Download icons/pdf.png";
import DOCImg from "../../../Images/File Download icons/DOC.png";
import DOCXImg from "../../../Images/File Download icons/DOCX.png";
import DOWNLOADImg from "../../../Images/File Download icons/DOWNLOAD.png";
import GIF from "../../../Images/File Download icons/GIF.png";
import jpeg from "../../../Images/File Download icons/jpeg.png";
import jpg from "../../../Images/File Download icons/JPG.png";
import ppt from "../../../Images/File Download icons/ppt.svg";
import pptx from "../../../Images/File Download icons/pptx.svg";
import xls from "../../../Images/File Download icons/xlst.svg";
import mp4 from "../../../Images/File Download icons/mp4.svg";

import { Link } from "react-router-dom";
import clodeImg from "../../../Images/icons/cancel_FILL0_wght400_GRAD0_opsz48.svg";
import Draggable from "react-draggable";

// import { Logger } from "sass";

export function OnlineResourse(props) {
  const [showVideo, setshowVideo] = useState(false);
  const [video, setvideo] = useState("a");
  const [currentDownloadFileLink, setcurrentDownloadFileLink] = useState("");
  let reqBody = props.fromSubjectContent == true ? props.reqBody : null;

  let currentList =
    props.fromSubjectContent == true
      ? props.content[1].slice(0, 4)
      : props.content;

  return (
    <>
      {/* {console.log("currentResourseIdName", props.currentResourseIdName)} */}
      {/* {console.log("reqBody resourse page", reqBody)} */}
      {/* {console.log("reqBody", props.reqBody)} */}
      {/* {console.log("props.content", props.content)} */}

      {/* {console.log("currentList", currentList)} */}
      {/* {console.log("video", video)} */}

      {showVideo ? (
        // <div className={s.resize}>
        // <Draggable>
        <div className={s.videoBox}>
          <div className={s.vidcloseBtn} onClick={() => setshowVideo(false)}>
            {/* <span class="material-icons videoClose">cancel</span> */}
            <img src={clodeImg} alt="" className={s.closeImg} />
          </div>
          <video className={s.video} controls>
            <source src={video} />
          </video>
        </div>
      ) : (
        // </Draggable>
        // </div>
        <div className={s.oContainer}>
          <div className={s.container}>
            {props.content == null
              ? null
              : currentList == null
              ? null
              : currentList.map((item) => (
                  <div
                    className={
                      props.forMultipleColumns ? s.forMultipleColumns : s.box
                    }
                  >
                    {item.thumbail == "" ? null : item.thumbail ==
                      null ? null : (
                      <img src={item.thumbail} alt="" className={s.img} />
                    )}
                    <div className={s.bottom}>
                      <div className={s.headPdf}>
                        <div className={s.head}>
                          {item.title == null ? null : (
                            <p className={s.heading}>{item.title}</p>
                          )}
                          {item.link == null ? null : item.link == "" ? null : (
                            <a
                              href={
                                (item.link.slice(0, 4) == "http"
                                  ? ""
                                  : "https://") +
                                // "/" +
                                item.link
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              className={s.link}
                            >
                              {item.link}
                            </a>
                          )}
                          {/* {console.log(item.link, item.link.slice(0, 4))} */}
                        </div>
                        <div className={s.pdfImgBox}>
                          {item.documentURL == "" ? null : item.documentURL ==
                            null ? null : (
                            <a href={item.documentURL} download target="_blank">
                              <img
                                src={
                                  item.documentURL.slice(
                                    item.documentURL.length - 3,
                                    item.documentURL.length
                                  ) == "pdf"
                                    ? pdfImg
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "doc"
                                    ? DOCImg
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "ocx"
                                    ? DOCXImg
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "gif"
                                    ? GIF
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "peg"
                                    ? jpeg
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "jpg"
                                    ? jpg
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "ppt"
                                    ? ppt
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "ptx"
                                    ? pptx
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "xls"
                                    ? xls
                                    : item.documentURL.slice(
                                        item.documentURL.length - 3,
                                        item.documentURL.length
                                      ) == "mp4"
                                    ? mp4
                                    : DOWNLOADImg
                                }
                                alt=""
                                className={s.pdfImg}
                              />
                              {/* {console.log(
                                "item.documentURL",
                                item.documentURL,
                                item.documentURL.slice(
                                  item.documentURL.length - 3,
                                  item.documentURL.length
                                )
                              )} */}
                            </a>
                          )}
                        </div>
                      </div>
                      {item.description_1 == null ? null : item.description_1 ==
                        "" ? null : (
                        <p className={s.para}> {item.description_1} </p>
                      )}
                      {item.description_2 == null ? null : item.description_2 ==
                        "" ? null : (
                        <p className={s.para}> {item.description_2} </p>
                      )}
                      {/* video_url */}
                      {item.videoURL == "" ? null : item.videoURL ==
                        null ? null : (
                        <div>
                          {/* {console.log("videoVideo", video)} */}
                          <button
                            className={s.videobtn}
                            onClick={() => {
                              setshowVideo(!showVideo);
                              setvideo(item.videoURL);
                            }}
                          >
                            Video
                          </button>
                          {/* {showVideo ? (
                          // <Draggable>
                          <div className={s.videoBox}>
                            <div
                              className={s.vidcloseBtn}
                              onClick={() => setshowVideo(false)}
                            >
                              <span class="material-icons videoClose">
                                cancel
                              </span>
                            </div>
                            <video className={s.video} controls>
                              <source src={item.videoURL} />
                            </video>
                          </div>
                        ) : //</Draggable>
                        null} */}
                        </div>
                      )}
                      {item.link == null ? null : item.link == "" ? null : (
                        <a
                          href={
                            (item.link.slice(0, 4) == "http"
                              ? ""
                              : "https://") +
                            // "/" +
                            item.link
                          }
                          target="_blank"
                          className={s.btn1Box}
                          rel={"external"}
                        >
                          <Button1 />
                        </a>
                      )}
                      {item.documentURL == "" ? null : item.documentURL ==
                        null ? null : (
                        <a
                          href={item.documentURL}
                          target="_blank"
                          download
                          className={s.downloadBtnBox}
                        >
                          {/* {console.table({
                            actualurl: item.documentURL,
                            encodeURI: encodeURI(item.documentURL),
                            encodeURIComponent: encodeURIComponent(
                              item.documentURL
                            ),
                            decodeURI: decodeURI(item.documentURL),
                            decodeURIComponent: decodeURIComponent(
                              item.documentURL
                            ),
                            decode_to_encode: encodeURIComponent(
                              decodeURIComponent(item.documentURL)
                            ),
                          })} */}
                          {/* {console.log(
                            "encodeURI",
                            encodeURI(
                              "https://woza-geleza-dev-s3.s3.eu-west-1.amazonaws.com/grade-4/maths/term-1/mental-maths/Numeracy+Booklet.pdf"
                            )
                          )}
                          {console.log(
                            "encodeURIComponent",
                            encodeURIComponent(
                              "https://woza-geleza-dev-s3.s3.eu-west-1.amazonaws.com/grade-4/maths/term-1/mental-maths/Numeracy+Booklet.pdf"
                            )
                          )}
                          {console.log(
                            "decode",
                            decodeURI(
                              "https://woza-geleza-dev-s3.s3.eu-west-1.amazonaws.com/grade-4/maths/term-1/mental-maths/Numeracy+Booklet.pdf"
                            )
                          )}
                          {console.log(
                            "decode to encode",
                            decodeURIComponent(
                              encodeURIComponent(
                                "https://woza-geleza-dev-s3.s3.eu-west-1.amazonaws.com/grade-4/maths/term-1/mental-maths/Numeracy+Booklet.pdf"
                              )
                            )
                          )} */}

                          {/* {console.log(
                            "TESTeNCODE",
                            encodeURI(item.documentURL)
                          )}
                          {console.log(
                            "dECODE",
                            encodeURIComponent(item.documentURL)
                          )}
                          {console.log("TEST", item.documentURL)} */}

                          <DownloadButton />
                        </a>
                      )}
                      {/* {console.log("item.documentURL", item.documentURL)} */}
                    </div>
                  </div>
                ))}
          </div>
          {props.fromSubjectContent == true ? (
            <Button2
              reqBody={reqBody}
              reDirect="/ViewAll"
              currentResourseId2={props.content[0].split("_")[1]}
              currentResourseName={props.content[0].split("_")[0]}
            />
          ) : null}
        </div>
      )}
    </>
  );
}

export function Button1(props) {
  return (
    <>
      <button className={s.btn1}>
        <div> Visit </div>
        <span class="material-icons subjecBtn1">east</span>
      </button>
    </>
  );
}

export function Button2(props) {
  return (
    <>
      <Link
        to={props.reDirect}
        state={{
          reqBody: props.reqBody,
          currentResourseId2: props.currentResourseId2,
          currentResourseName: props.currentResourseName,
        }}
        className={s.btn2Box}
      >
        <button className={s.btn2}>
          <div>View All</div>{" "}
          <span class="material-icons subjecBtn2">east</span>
        </button>
      </Link>
    </>
  );
}

function DownloadButton() {
  return (
    <>
      <button className={s.downloadButton}>
        <span class="material-icons subjectContentDownload">file_download</span>
        <div>Download File</div>
      </button>
    </>
  );
}

// export function OtherVariousSites() {
//   return (
//     <>
//       <div className={s.oContainer}>
//         <div className={s.container}>
//           {onlineResourseContent?.map((item) => (
//             <div className={s.box}>
//               <div className={s.bottom}>
//                 <p className={s.heading}>{item.heading}</p>
//                 <div className={s.bottombox}>
//                   <p className={s.link}> {item.link} </p>
//                   <Button1 />
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <Button2 />
//       </div>
//     </>
//   );
// }
