import React from "react";
import s from "./TopTitle.module.css";

export function TopTitle(props) {
  document.title =
    props.TrainingMaterialCondition == true
      ? props.Subjecttitle + " | Woza Geleza"
      : props.GradeTitle +
        " - " +
        props.Subjecttitle +
        // (props?.GradeTitle?.toLowerCase() == "grade 1" ||
        // props?.GradeTitle?.toLowerCase() == "grade 2" ||
        // props?.GradeTitle?.toLowerCase() == "grade 3"
        //   ?
        " - " +
        props.TermTitle +
        // : "")
        " | Woza Geleza";
  return (
    <>
      <h4 className={s.title}>
        {
          props.TrainingMaterialCondition == true
            ? props.Subjecttitle
            : props.GradeTitle +
              " - " +
              props.Subjecttitle +
              // (props.GradeTitle.toLowerCase() == "grade 1" ||
              // props.GradeTitle.toLowerCase() == "grade 2" ||
              // props.GradeTitle.toLowerCase() == "grade 3"
              //   ?
              " - " +
              props.TermTitle
          // : "")
        }
        {/* {  props.GradeTitle} - 
        {props.Subjecttitle} -
        { props.TermTitle} */}
      </h4>
    </>
  );
}
