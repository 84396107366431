// const baseUrl = "https://13.114.105.241/admin/api/";

// const baseUrl = "http://54.73.219.207/admin/api/";
const baseUrl = "https://www.woza-geleza.com/admin/api/";


// const baseUrl = "https://woza.geleza.admin.rudimax.com/api/";
// const baseUrl = "http://13.114.105.241/api/";
// const apiDocumentationUrl =
//   "https://woza.geleza.admin.rudimax.com/api/documentation#/";
// const profileUrl = "https://woza.geleza.admin.rudimax.com/api/profile/list";
// const subMenuUrl = "https://woza.geleza.admin.rudimax.com/api/sub/menu/list/";
// const gradeUrl = "https://woza.geleza.admin.rudimax.com/api/grade/list/";
// const SubjectUrl =
//   "https://woza.geleza.admin.rudimax.com/api/grade/subject/list/";

// const trainingVendorListUrl =
//   "https://woza.geleza.admin.rudimax.com/api/training/vendor/list";

// const signInpageLink = "http://13.114.105.241/admin/";

const signInpageLink = "https://www.woza-geleza.com/admin/sign-in";

 


export { baseUrl, signInpageLink };

// Two API:
// 1. Method: GET
//     URL: https://woza.geleza.admin.rudimax.com/api/profile/list
// 2. Method: GET
//     URL: https://woza.geleza.admin.rudimax.com/api/sub/menu/list/{typeID}
//     example: typeID -->> 985a2554-2c36-458c-865c-4b915228df78

// Check it and any doubut ping me

// Two API:
// 3. Method: GET
//     URL: https://woza.geleza.admin.rudimax.com/api/grade/list/{gradeID}
// example: gradeID -->> 985a2554-2c36-458c-865c-4b915228df78
// 4. Method: GET
//     URL: https://woza.geleza.admin.rudimax.com/api/grade/subject/list/{gradeID}
//     example: gradeID -->> 985a2554-2c36-458c-865c-4b915228df78

// If you have any questions, please let me know
