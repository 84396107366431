import book from "../../../Images/Company Logos/Grades/book.svg";
import beaker from "../../../Images/Company Logos/Grades/beaker.svg";
import target from "../../../Images/Company Logos/Grades/target.svg";
import timer from "../../../Images/Company Logos/Grades/timer.svg";

import maths from "../../../Images/Photos for Dashboard/Photos for subject/Maths.jpg";
import English from "../../../Images/Photos for Dashboard/Photos for subject/English.jpg";
import Physical from "../../../Images/Photos for Dashboard/Photos for subject/Physical Science.jpg";
import Natural from "../../../Images/Photos for Dashboard/Photos for subject/Natural Science.jpg";
import Home from "../../../Images/Photos for Dashboard/Photos for subject/Home Language.jpg";

const gradeSubject = [
  {
    subImage: book,
    subTitle: "Maths",
    background: maths,
    backgroundImage: "linear-gradient(to right, #347ff6, rgba(26, 64, 123, 0))",
    link: "/Mathematics",
  },
  {
    subImage: beaker,
    subTitle: "English",
    background: English,
    backgroundImage:
      "linear-gradient(to right, rgb(255, 140, 0), rgba(128, 70, 0, 0))",
    link: "/English",
  },
  {
    subImage: target,
    subTitle: "physical science",
    background: Physical,
    backgroundImage:
      "linear-gradient(to right, rgb(185, 12, 120), rgba(93, 6, 60, 0))",
    link: "/Physical-Science",
  },
  {
    subImage: timer,
    subTitle: "natural science",
    background: Natural,
    backgroundImage:
      "linear-gradient(to right, rgb(25, 235, 220), rgba(13, 118, 110, 0))",
    link: "/Natural Science",
  },
  {
    subImage: book,
    subTitle: "Maths",
    background: Home,
    backgroundImage: "linear-gradient(to right, #347ff6, rgba(26, 64, 123, 0))",
    link: "/Mathematics",
  },
  {
    subImage: book,
    subTitle: "Maths",
    background: maths,
    backgroundImage: "linear-gradient(to right, #347ff6, rgba(26, 64, 123, 0))",
    link: "/Mathematics",
  },
  {
    subImage: beaker,
    subTitle: "English",
    background: English,
    backgroundImage:
      "linear-gradient(to right, rgb(255, 140, 0), rgba(128, 70, 0, 0))",
    link: "/English",
  },
  {
    subImage: target,
    subTitle: "physical science",
    background: Physical,
    backgroundImage:
      "linear-gradient(to right, rgb(185, 12, 120), rgba(93, 6, 60, 0))",
    link: "/Physical-Science",
  },
  {
    subImage: timer,
    subTitle: "natural science",
    background: Natural,
    backgroundImage:
      "linear-gradient(to right, rgb(25, 235, 220), rgba(13, 118, 110, 0))",
    link: "/Natural Science",
  },
  {
    subImage: book,
    subTitle: "Maths",
    background: Home,
    backgroundImage: "linear-gradient(to right, #347ff6, rgba(26, 64, 123, 0))",
    link: "/Mathematics",
  },
  {
    subImage: book,
    subTitle: "Maths",
    background: maths,
    backgroundImage: "linear-gradient(to right, #347ff6, rgba(26, 64, 123, 0))",
    link: "/Mathematics",
  },
  {
    subImage: beaker,
    subTitle: "English",
    background: English,
    backgroundImage:
      "linear-gradient(to right, rgb(255, 140, 0), rgba(128, 70, 0, 0))",
    link: "/English",
  },
  {
    subImage: target,
    subTitle: "physical science",
    background: Physical,
    backgroundImage:
      "linear-gradient(to right, rgb(185, 12, 120), rgba(93, 6, 60, 0))",
    link: "/Physical-Science",
  },
  {
    subImage: timer,
    subTitle: "natural science",
    background: Natural,
    backgroundImage:
      "linear-gradient(to right, rgb(25, 235, 220), rgba(13, 118, 110, 0))",
    link: "/Natural Science",
  },
  {
    subImage: book,
    subTitle: "Maths",
    background: Home,
    backgroundImage: "linear-gradient(to right, #347ff6, rgba(26, 64, 123, 0))",
    link: "/Mathematics",
  },
];

export default gradeSubject;
